import { useRouter } from "next/router";

export default function Custom404() {
  const router = useRouter();
  return (
    <div>
      <h1>Error 404</h1>
      <p>{`Could not found the requested page ${router.asPath}.`}</p>
    </div>
  );
}
